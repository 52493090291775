import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MachineService {
  // public baseUri = `${environment.WebApiBaseUrl}`;
  public baseUri = `${environment.WebApiBaseUrl}`;
  public MachineModuleUri = `${environment.WebApiBaseUrl}machine`;
  pagedData: any;
  constructor(private http: HttpClient) { }
  saveMachine(machine: FormData) {
    return this.http.post(`${this.baseUri}Machine`, machine);

  }
  getMachineList(nameSearch: any, active: number, location: any, sortColumn: string, sortDir, page: number, pageSize: number, isExport = false) {
    return this.http.get(this.baseUri + `machine/GetMachineList?nameSearch=${nameSearch}&active=${active}&location=${location}&SortColumn=${sortColumn}&SortDir=${sortDir}&PageNo=${page}&PageSize=${pageSize}&isExport=${isExport}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          return true;
        })
      );
  }
  GetLocationDDL() {
    return this.http.get(`${this.MachineModuleUri}/GetLocationDDL`);
  }
  GetMachineTypeDDl() {
    return this.http.get(`${this.MachineModuleUri}/GetMachineTypeDDl`);
  }
  RemoveMachines(selected: string) {
    return this.http.get(this.baseUri + `Machine/RemoveMachinesByIds?macIds=${selected}`)
  }
  DeleteMachine(MachineId: any) {
    return this.http.get(this.baseUri + `Machine/DeleteMachineById?MachineId=${MachineId}`)
  }
  setMachineStatus(MachineId: any, IsActive: any) {
    return this.http.get(this.baseUri + `Machine/ChangedMachineStatusById?MachineId=${MachineId}&IsActive=${IsActive}`)
  }
  GetMachineById(Id: string) {
    return this.http.get<Machine>(`${this.MachineModuleUri}/GetMachineById?Id=${Id}`)
      .pipe(
        tap(data => console.log('Machine DAta by Id', data)),
      );
  }
  GetSubscriptionDataDetails(Id: string) {
    return this.http.get(this.baseUri + `Machine/GetSubscriptionDataDetails?Id=${Id}`)
  }
  CheckMachineSerial_MacAddIsExist(MacAddress: any, SerialNo: any, machineId: any) {
    return this.http.get(this.baseUri + `Machine/CheckMachineSerial_MacAddIsExist?MacAddress=${MacAddress}&SerialNo=${SerialNo}&machineId=${machineId}`)

  }
  //Assign Machine

  getAssignMachineList(nameSearch: any, sortColumn: string, sortDir, page: number, pageSize: number, userId: number, machineId: number, customerTypeId: number, customerId: number) {
    return this.http.get(this.baseUri + `machine/GetAssignedMachineList?nameSearch=${nameSearch}&SortColumn=${sortColumn}&SortDir=${sortDir}&PageNo=${page}&PageSize=${pageSize}&UserId=${userId}&machineId=${machineId}&customerTypeId=${customerTypeId}&customerId=${customerId}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          return true;
        })
      )
  }
  saveAssignMachine(machine: AssignMachine) {
    return this.http.post(`${this.baseUri}Machine/SaveAssignMachine`, machine);
  }
  AddNewMachineAssign(machine: NewAssignMachineModel) {
    return this.http.post(`${this.baseUri}Machine/SaveAssignMachine`, machine);
  }
  CheckUsertype(userId: number) {
    return this.http.get(`${this.MachineModuleUri}/CheckUsertype?UserId=${userId}`);
  }
  getMachineListDDl(userId: number) {
    return this.http.get(`${this.MachineModuleUri}/getMachineDDl?UserId=${userId}`);
  }
  GetNewMachineListForDisplayDetails(userId: number, customerTypeIdDDL: any, customerIdDDL: any) {
    return this.http.get(`${this.MachineModuleUri}/GetNewMachineListForDisplayDetails?UserId=${userId}&customerTypeIdDDL=${customerTypeIdDDL}&customerIdDDL=${customerIdDDL}`);
  }
  getCusotmerNameDDl(Id: string, userId: number) {
    return this.http.get(`${this.MachineModuleUri}/getCusotmerNameDDl?Id=${Id}&UserId=${userId}`).pipe(
      tap(data => console.log('Machine DAta by Id', data)),
    );
  }
  CheckMachineIsAssigntoCustomer(CustomerId) {
    return this.http.get(`${this.MachineModuleUri}/CheckMachineIsAssigntoCustomer?CustomerId=${CustomerId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  CheckAssignMachineIsExist(id: string, userId: number, CustomerTypeId: number, CustomerId) {
    return this.http.get(`${this.MachineModuleUri}/CheckAssignMachineIsExist?id=${id}&UserId=${userId}&CustomerTypeId=${CustomerTypeId}&CustomerId=${CustomerId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  UnassignMachines(selected: string, userId: number) {
    return this.http.get(this.baseUri + `Machine/UnassignMachinesByIds?macIds=${selected}&UserId=${userId}`)
  }
  CheckIfCustomerAssignMachineFurther(machineID: string, assignedMachineId: string) {
    return this.http.get(this.baseUri + `Machine/CheckIfCustomerAssignMachineFurther?machineid=${machineID}&assignedMachineId=${assignedMachineId}`)
  }
}
export class Machine {
  assignedMachineId: string;
  machineId: string;
  locationId: string;
  machineName: string;
  machineTypeId: string;
  serialNumber: string;
  macAddress: string;
  ipAddress: string;
  machineDescription: string;
  isActive: boolean;
  asociatedUser: boolean;
  subscriptionId: string;
  startDate: string;
  endDate: string;
  termsInMonth: string;
  assignClinic: string;
  freeEmployeeIDs: string;
  constructor() {
    this.assignedMachineId = '',
      this.machineId = '',
      this.locationId = '',
      this.machineName = '',
      this.machineTypeId = '',
      this.serialNumber = '',
      this.macAddress = '',
      this.ipAddress = '',
      this.machineDescription = '',
      this.subscriptionId = '';
    this.isActive = false,
      this.asociatedUser = false,
      this.startDate = '',
      this.endDate = '',
      this.termsInMonth = '',
      this.assignClinic = ''
    this.freeEmployeeIDs = ''

  }
}
export class AssignMachine {
  AssignedMachineId: string;
  MachineId: string;
  CustomerTypeId: string;
  CustomerId: string;
  constructor() {
    this.AssignedMachineId = '',
      this.MachineId = '',
      this.CustomerTypeId = '',
      this.CustomerId = ''

  }
}

export class NewAssignMachineModel {
  id: string;
  customerTypeId: string;
  customerId: string;
  data: string;
  constructor() {
    this.id = '',
      this.customerTypeId = '',
      this.customerId = '',
      this.data = ''
  }
}
